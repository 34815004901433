export const experienceData = [
    // {
    //     id: 1,
    //     company: 'Globex Corporation',
    //     jobtitle: 'Frontend Developer',
    //     startYear: '2018',
    //     endYear: '2019'
    // },
    // {
    //     id: 2,
    //     company: 'Vehement Capital Partners',
    //     jobtitle: 'Backend Developer',
    //     startYear: '2019',
    //     endYear: '2020'
    // },
    {
        id: 2,
        company: 'YapBuzz LLC',
        jobtitle: 'Sr. NodeJs Developer',
        startYear: ' 06 JUN 2022 ',
        endYear: ' Present ',
        address: "Emory, Texas, United States"
    },
    {
        id: 1,
        company: 'Ricomputech Pvt Ltd',
        jobtitle: 'Intern NodeJs Developer',
        startYear: ' 01 JAN 2022 ',
        endYear: ' 06 JUN 2022 ',
        address: "Kolkata, West Bengal, India"
    },

]